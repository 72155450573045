
body{
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: 0;
    padding: 0;
  }
  #root
  {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }
  .teledrive-icon {
    width: 50px;
    height: 50px;
    margin: 3px;
  }
  .ethio-icon {
    width: 120px;
    height: 35px;
    margin: 1px;
  }
.telebirr-icon
{
width: 110px;
    height: 50px;
    margin: 0px;
}
  .UserInfo
  {
    display: flex;
    flex-direction: column;
    padding: 10px 5px 10px 10px;
    width: 94%;
    height: 35px;
    font-size: 14px;
    font-weight: bold;
    color: #5e5e5e;
    background-color: #f5f5f5;
    border: 1px solid #c4c4c4;
    box-shadow: 0px 0px 5px 2px rgba(170, 170, 170, 0.2);
    align-items: flex-start;
    justify-content: center;
  }
  .customerNumber
  {
    font-size: 16px;
  }
  .customerName
  {
    font-size: 12px;
  }
  .header-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 80px;
    align-content: center;
    justify-content: center;
    margin: 0px;
    padding-top: 10px;
    background-color: #fff;
  }
  .header-div1
  {
    display: flex;
    flex-direction: column;
    width: 30%;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
  .header-div
  {
    display: flex;
    flex-direction: column;
    width: 30%;
    height: 100%;
    align-items: flex-end;
    justify-content: center;
  }
  
  .phone-number {
    position: relative;
    display: inline-block;
    width: 90%;
    height: 19px;
    flex-shrink: 0;
  }
  /* .phone_number_div
  {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 90%;
  } */
  .productIntroPage
  {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    justify-content: center;
    background-color: #ffffff;
    height: 95%;
  }
  .bodyImage
  {
    display: flex;
    width:90%;
    align-items: center;
    justify-content: center;
    overflow-y: scroll;
    font-size: 15px;   
  }
  /* .phone_number_div1
  {
 
    display: flex;
    width: 94%;
    overflow-y: visible;
    font-size: 15px;  

  } */
  .phone-number-container {
    display: flex;
    flex-direction: column;
    line-height: 2.5em;
    line-height: 20px;
    width: 90%;
    text-justify: center;
    text-align: center
  }
  
  .p{
    text-justify: center;
  }
  .btn_subscribe
  {
  
    display: flex;
    cursor: pointer;
    bottom: 0;
    width: 100%;
    height: 35px;
    border: 0;
    padding: 0;
    height: 50px;
    align-items: center;
    justify-content: center;
  }
  .phone_number_div_submit
  {
    border: 1px solid #e5e5e5;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 5px 5px 5px rgba(219, 219, 219, 0.2);
    display: flex;
    width: 94%;
    overflow-y: visible;
    font-size: 15px;  
    padding: 20px 0px 25px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  
  }
  .phone_number_div_submit1
  {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
    padding-top: 20px;
  }
  
  /* .phone-number-container-child {
    border: 0;
    outline: 0;
    background-color: transparent;
    position: relative;
    width: 100%;
    padding-left: 5px;
  } */
  /* .phone_number_div_submit
  {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  .phone_number_div_submit
  {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  
  } */
  /* .phone-number-container {
    border-radius: 5px;
    border: 1px solid #c9c8c8;
    box-sizing: border-box;
    width: 90%;
    height: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  } */
  .next {
    position: relative;
    font-size: 14px;
    font-family: var(--font-inter);
    color: var(--color-black);
    text-align: left;
  }
  .next1 {
    position: relative;
    font-size: 18px;
    font-family: var(--font-inter);
    color: var(--color-black);
    text-align: left;
    font-weight: 500;
  }
  
  /* .btn-div {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 35px;
      border-radius: 10px;
      background-color: #97e815;
      border: 1px solid #699c16;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: var(--padding-3xs) 0;
  }  */
  .btn-conatiner {
  
    width: 80%;
    height: 40px;
    border-radius: 10px;
    background-color: #97e815;
    border: 1px solid #699c16;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: var(--padding-3xs) 0;
   
  }
  .btn-conatiner:active {
  
    width: 70%;
    height: 35px;
    border-radius: 5px;
    background-color: #456c07;
    border: 1px solid #324b0a;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: var(--padding-3xs) 0;
  }
  .teledrivecontainer {
    height: 100%;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    
  }
  .mainpage {
   display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    gap: 3px;
    background-color: #ebebeb;
    align-items: center;
    justify-content: flex-start;
    padding: 0px;
    margin: 0px;
  }
  .teledrivecontainer {
    background-color: #fff;
    width: 100%;
   
    overflow: hidden;
    flex-direction: row;
    padding: var(--padding-3xs) 0;
    text-align: left;
    font-size: 13px;
    color: var(--color-black);
    font-family: var(--font-inter);
  }
  .teledrive {
    color: #f4bb29;
  }
  .welcome-to-teledrive-container {
    position: relative;
  }
  .HomeIntro
  {
    font-size: 14px;
  }
  .welecom-container {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 5px 0px 0px 0px;
    font-size: 20px;
    font-weight: bolder;
  }
  .welecom-container .welcome {
    color: #2b92dd;
  }
  .radio-container{
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .radio-container label {
    display: block;
     /* Display each label on a separate line */
    margin-bottom: 10px; /* Add spacing between the buttons */
    background-color: #f0f0f0; /* Light gray background */
    width: 85%;
    padding: 10px;
    min-height: 30px;
    border: 1px solid #c9c8c8;
    border-radius: 5px;
    font-size: 13px;
  }
  
  .btn_sbscription
  {
    box-sizing: border-box;
    width: 85%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-top: 10px;
  }
  
  .error-message
  {
    color: #db2d2d;
  }
  .subMainPage
  {
    display: flex;
    flex-direction: column;
    width: 95%;
    border: 1px solid #989898;
    cursor: pointer;
    min-height: 120px;
    border: 1px solid #dad9d9;
    border-radius: 5px;
    box-shadow: 2px 0px 10px 5px rgba(206, 205, 205, 0.2);
    background-color: #fff;
    transition: 0.3s;
  }
  .subMainPage:hover {
    box-shadow: 0 0px 15px 10px rgba(171, 171, 171, 0.2);
  }
  .subscriptioninfo
  {
  
    display: flex;
    flex-direction: column;
    min-height: 100px;
    padding: 20px;
    font-size: 14px;
    gap: 30px;
    box-sizing: border-box;
    font-size: 15px;
    color: #575656;
  }
  .subscriptioninfocontent{
    font-size: 18px;
    font-weight: 900;
  }
  .subscriptioninfocontentdisc
  {
    font-size: 14px;
    font-weight: 500;
    text-align: justify
  }
  
  .subDetailPage table,
  .subscriptioninfo table
  {
    border-collapse: separate;
    border-spacing: 30px;
  }
  .subDetailPage td,
  .subscriptioninfo td {
    padding: 7px;
  }
  .subscriptioninfotitle
  {
    white-space: nowrap;
    overflow: hidden
  }
  .popup {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    position: absolute;
    width: 100%;
    height: 100%;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    padding: 0px;
    z-index: 10;
  
  }
  .popup-wait {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    gap:15px;
    width: 100%;
    height: 100%;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    padding: 0px
  }
  .popupchild {
    width: 200px;
    height: 50px;
    border: 1px solid #848181;
    background-color: #f0f0f0;
    border-radius: 5px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 10
  }
  .popup-text
  {
    color: #7cb7e1;
    font:14px
  }
  .popup-text_try
  {
    color: #ea5e33;
    font:14px
  }
  .btn_try
  {
    width: 100px;
    height: 25px;
    border-radius: 3px;
    color: #5a5a5a;
    outline: 0;
    border: 1px solid #6b6a6a;
  }
  .subscriptioninfochild{
    display: flex;
    flex-direction: row;
    width: 94%;
    height: 98%;
    background-color: #fff;
    border: 1px solid #d9d9d9;
  }
  .subscriptionDetail
  {
    display: flex;
    flex-direction: column;
  
  }
  .subDetailPage
  {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    gap: 30px;
    width: 95%;
    min-height:70%;
    border: 1px solid #989898;
    cursor: pointer;
    border: 1px solid #dad9d9;
    border-radius: 5px;
    box-shadow: 2px 0px 10px 5px rgba(206, 205, 205, 0.2);
    background-color: #fff;
    transition: 0.3s;
    overflow-y: auto;
    padding-bottom: 5px;
  }
  .subscriptionDetailinfo
  {
    padding: 20px;
    height: 70%;
  }
  
  
  .change-password-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
  }
  
  .setPasswordlogo
   {
    display: flex;
    width: 100%;
    align-items: flex-start;
    margin-bottom: 1rem;
  }
  .setPasswordTitle
  {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
    align-items: center;
  }
  .teledrive-text {
    display: flex;
    font-size: 18px;
    color: #ffc112;
    font-weight: bolder;
  }
  .change-password-text
  {
    display: flex;
    font-size: 18px;
    color: #0072bc;
    font-weight: bolder;
  }
  h2 {
    font-size: 1.2em;
    margin: 0px;
    font-weight: bolder;
    unicode-bidi: isolate;
  }
  .password-input-container {
    display: flex;
    flex-direction: column;
    gap:15px;
    margin-top: 60px;
    width: 100%;
  }
  
  .password-input
  {
    display: flex;
    flex-direction: column;
    height: 40px;
    gap: 0;
    font-size: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 5px;
  }
  
  .password-input-eye input[type="password"] {
    border: 0px solid #ccc;
    height: 25px;
    width: 95%;
    outline: 0;
  }
  .password-input-eye input[type="text"] {
    border: 0px solid #ccc;
    height: 25px;
    width: 95%;
    outline: 0;
  }
  .password-eye
  {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    width: 5%;
    height: 25px;
  cursor: pointer;
  }
  .password-strength {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    gap: 5px;
    font-size: 0.8rem;
    color: #ccc;
  }
  
  .button-container {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    width: 100%;
    gap: 5px;
  }
  
  .button-container button {
    height: 30px;
    border: 1px solid #03578f;
    border-radius: 25px;
    cursor: pointer;
    background-color: #0072bc;
    transition: background-color 0.2s ease-in-out;
    color:#fff;
  }
  
  .button-container button:hover {
    background-color: #043e65; 
  }
  .set-password-cancel
  {
    color: #03578f;
    font-size: 14px;
    font-weight: bold;
    margin-top: 5px;
  }
  
  .password-container {
    position: relative; /* Necessary for absolute positioning of the icon */
  }
  
  #togglePassword {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%); /* Center the icon vertically */
    cursor: pointer; /* Indicate clickable behavior */
    color: #ccc; /* Adjust color as needed */
  }
  .password-input-eye
  {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  .loaderMainPage
  {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
  .Message_page_main
  {
    display: flex;
    flex-direction: column;
    width: 95%;
    height: 95%;
    justify-content: center;
    align-items: center;
    background-color: #fff;
  }
  .Message_page_success
  {
    display: flex;
    width: 80%;
    height: 350px;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 18px;
    color: #699c16;
  }
  .Message_page_failed
  {
    display: flex;
    width: 80%;
    height: 350px;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 18px;
    color: #db2d2d;
  }
  .toast-error {
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
   right: 0;
    background-color: #f08f88;
    font-size: 14px;
   justify-content: center;
   align-items: center;
   color: #191818;
  }

  .teledriveSubDetailPage
  {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    gap: 10px;
    width: 95%;
    height: 100%;
    padding: 30px 10px 30px 10px;
    border: 1px solid #989898;
    cursor: pointer;
    border: 1px solid #dad9d9;
    border-radius: 5px;
    box-shadow: 2px 0px 10px 5px rgba(206, 205, 205, 0.2);
    background-color: #fff;
    transition: 0.3s;
  }
  .Message_sub
  {
    display: flex;
    width: 90%;
    height: 50%;
    padding: 20px;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 16px;
    color: #545454;
    line-height: 1.5
  }
  .popupConfirmation {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    position: absolute;
    width: 100%;
    height: 100%;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    padding: 0px;
    z-index: 10;
    border:1px solid #545454;
    background-color: rgba(0, 0, 0, 0.7);
  }
  .popupConfirmationchild {
    width: 300px;
    height: 150px;
    border: 1px solid #848181;
    background-color: #f8f8f8;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #848181;
    border-radius: 5px;
    flex-direction: column;
    opacity: 10;
  }
  
  .popupConfirmationHead {
    display: flex;
    height: 45px;
    padding: 5px;
    width: 97%;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    background-color: #d7d7d7;
  }
  .popupConfirmationBody {
    display: flex;
    flex-direction: column;
    height: 140px;
    margin: 5px;
    width: 100%;
    justify-content: center;
    align-items: center;

  }
  .popupConfirmationfooter {
    height: 35px;
    display: flex;
    flex-direction: row;
    gap: 20px;
    padding: 10px;
  }
.btn{
  height:30px;
  width:70px
}

.teledrive-pricing {
  text-align: center;
  width: 100%;
}

.free-trial {
  margin-bottom: 20px;
  border: 1px solid #3ed37a;
  border-radius: 20px;
  background-color: #3ed37a;
  color: #fff;
}

.start-trial-button {
  background-color: #007BFF;
  color: #fff;
  border: none;
  width: 70%;
  height: 35px;
  border-radius: 10px;
  padding: 10px 20px;
  cursor: pointer;
  margin-bottom: 5px;
}

.plans {
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 10px;
  margin-bottom: 10px;
}

.plan1 {
  position: relative;
  text-align: center;
  width: 40%;
  border: 1px solid #168f46;
  padding: 10px;
  border-radius: 20px;
  color: #168f46;
}
.plan2 {
  position: relative;
  text-align: center;
  width: 40%;
  border: 1px solid #007BFF;
  padding: 10px;
  border-radius: 20px;
  color: #007BFF;
}

.plan1:hover
{
  border: 1px solid #6e3607;
}
.plan2:hover
{
  border: 1px solid #6e3607;
}

.selected-circle {
  position: absolute;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  top: -3%;
  right: 0;
  width: 20px;
  height: 20px;
  background-color: green;
  border-radius: 50%;

}
.plan-type
{
  width: 100%;
 align-content: left;
 justify-content: left;
 text-align: left;
}
h3 {
margin: 5px;
}
h2 {
  margin: 5px;
  }
  .plan-sunscribe
  {
    font:22px;
    font-weight: 700;
    margin-top: 30px;
    color: #168f46;
  }
/* .select-offer{
  position: absolute;
  top:0px;
  background-color: #3ed37a;
  border-radius: 50%;
  width: 30px;
  height: 30px;
} */

.subscribe-button {
  background-color: #007BFF;
  color: #fff;
  border: none;
  width: 85%;
  height: 50px;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 10px;
}
.most-popular-title{
  display: flex;
  position: absolute;
  top: -7%;
  left: 50%;
  transform: translateX(-50%);
  justify-items: center;
  justify-content: center;
  width: 100%;
  height: 35px;
}
.most-popular-title1{
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  vertical-align: baseline;
  background-color: #007BFF;
  border-radius: 5px;
  width: 60%;
  padding-top: 5px;
  
}
.most-popular {
  position: relative;
  margin-top: 30px;
  margin-bottom: 20px;
  border: 1px solid #3ed37a;
  border-radius: 20px;
  font-size: 20px;
  background-color: #3ed37a;
  color: #fff;
}

.premium-plan {

  padding: 20px;
}
